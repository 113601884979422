<template>
	<div class="container" style="background: #fff;">
		<div style="padding: 20px;display: flex;align-items: center;">
            <div class="filter-item">
				<label class="label" style="display: inline-block;width: 90px;">优惠券名称:</label>
				<el-input clearable v-model="searchKey" style="width: 250px;" placeholder="优惠券名称"></el-input>
			</div>
			<div class="filter-item">
				<label class="label" style="display: inline-block;width: 80px;">赠送人ID:</label>
				<el-input clearable v-model="giveId" style="width: 250px;" placeholder="赠送人ID"></el-input>
			</div>
			<div class="filter-item">
				<label class="label" style="display: inline-block;width: 80px;">接收人ID:</label>
				<el-input clearable v-model="userId" style="width: 250px;" placeholder="接收人ID"></el-input>
			</div>
			<el-button type="primary" style="width:90px;margin-left: 20px;" @click="handleFilter">搜索</el-button>
		</div>
		<div class="table-container" style="padding: 0 20px;">
			<el-table :data="vipList" style="width: 100%;"
			v-loading="listLoading">
				<el-table-column prop="couponName" label="优惠券名称"></el-table-column>
				<el-table-column prop="faceMoney"  label="优惠券金额"></el-table-column>
				<el-table-column prop="verificationCode"  label="优惠券券码"></el-table-column>
				<el-table-column prop="createTime" label="赠送时间"></el-table-column>
				<el-table-column prop="giveUserId" label="赠送人id"></el-table-column>
				<el-table-column prop="getUserId" label="接收人id"></el-table-column>
				<el-table-column prop="timeValue" label="剩余有效期"></el-table-column>
				<el-table-column prop="statusOldValue" label="赠送前状态"></el-table-column>
				<el-table-column prop="statusValue" label="赠送后状态"></el-table-column>
			</el-table>
		</div>
		<div style="display: flex;justify-content: flex-end;">
			<el-pagination v-if="total" style="padding:20px 0;margin-right: 30px;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		findGiveCouponAll,
	} from '@/api/wyUsedInterface.js'
	import config from '@/config/index';
    import buttonPermissions from '@/components/buttonPermissions';
	export default {
        components: {
            buttonPermissions
        },
		data() {
			return {
				vipList: [],
                listLoading: false,
                userId:'',  //得到人id
				giveId:'',//赠送人id
				searchKey: '',
				pageNo: 1,
				pageSize: 20,
				total: 0,
				//状态 0 待使用，1 已使用，2 已过期
				statusValue:[
					'待使用',
					'已使用',
					'已过期'
				],
			}
		},
		created() {
			this.initData();
		},
		methods: {
			async initData() {
				try {
					this.listLoading = true;
					let data = {
						pageNo: this.pageNo,
						pageSize: this.pageSize,
						couponName:this.searchKey,
						getUserId:this.userId,//得到人id
						giveUserId:this.giveId,//赠送人id
					};
					let res = await findGiveCouponAll(data);
					res.data.records.map(item=>{
						item.statusValue =this.statusValue[item.couponStatus];
						item.statusOldValue =this.statusValue[item.couponOldStatus];
						item.timeValue = this.getTime(item.seconds);
						return item;
					})
					
					this.vipList = res.data.records;
					this.total = res.data.total;
				} catch (e) {
					//TODO handle the exception
				}finally{
					this.listLoading = false;
				}
			},
			getTime(time){
				var day = parseInt(time / 60 / 60 / 24);
				var hour = parseInt(time / 60 / 60 % 24);
				var minute = parseInt(time / 60 % 60);
				var second = parseInt(time % 60);
				return (day < 10 ? '0' + day + '天' : day + '天') + (hour < 10 ? '0' + hour + '时' : hour + '时') + (
					minute < 10 ? '0' + minute + '分' : minute + '分') + (second < 10 ? '0' + second + '秒' : second + '秒');
			},
			handleFilter() {
				this.pageNo = 1;
				this.initData();
			},
	
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.initData();
			},
			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.pageNo = val;
				this.initData();
			},
		}
	}
</script>

<style scoped lang="less">
	
</style>
